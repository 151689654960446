import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'

import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import { UserProvider } from './context/UserContext'

console.info(
  'GATEWAY_URL set?',
  window.GATEWAY_URL ? 'true' : 'false - falling back to localhost:3000',
)

// middlewares
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares)),
)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <UserProvider>
      <Localization>
        <Router history={history} />
      </Localization>
    </UserProvider>
  </Provider>,
  document.getElementById('root'),
)

if (window.Cypress) {
  window.store = store
}

export { store, history }
