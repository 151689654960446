import React from 'react'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div
        id="header-search-root"
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      ></div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <div id="header-buttons-root" style={{ width: '210px' }}></div>
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
