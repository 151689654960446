// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_dropdown__15ICG {\n  padding-right: 15px;\n  position: relative;\n  cursor: pointer; }\n  .style_dropdown__15ICG::after {\n    opacity: 0.5;\n    color: #a1a1c2;\n    position: absolute;\n    top: 50%;\n    right: 0;\n    margin-top: -2px;\n    display: inline-block;\n    width: 0;\n    height: 0;\n    margin-left: 0.255em;\n    vertical-align: 0.255em;\n    content: '';\n    border-top: 0.3em solid;\n    border-right: 0.3em solid transparent;\n    border-bottom: 0;\n    border-left: 0.3em solid transparent;\n    transition: all 0.2s ease-in-out; }\n  .style_dropdown__15ICG:hover {\n    color: var(--kit-color-primary); }\n    .style_dropdown__15ICG:hover::after {\n      color: #595c97; }\n\n.style_avatar__1Hoh8 {\n  background-color: #e4e9f0 !important; }\n\n[data-kit-theme='dark'] .style_avatar__1Hoh8 {\n  background: #232135 !important; }\n", ""]);
// Exports
exports.locals = {
	"dropdown": "style_dropdown__15ICG",
	"avatar": "style_avatar__1Hoh8"
};
module.exports = exports;
