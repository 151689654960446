import React, { useState, createContext } from 'react'

const MenuContext = createContext(undefined)

export const MenuProvider = ({ children }) => {
  const [notes, setNotes] = useState(null)
  const [currNoteId, setCurrNoteId] = useState(null)
  const [chartId, setChartId] = useState('')

  function setMenuPanelNotes(notes) {
    setNotes(notes)
  }

  const providerValue = {
    notes,
    currNoteId,
    chartId,
    actions: {
      setMenuPanelNotes,
      setCurrNoteId,
      setChartId,
    },
  }
  return (
    <MenuContext.Provider value={providerValue}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuContext
