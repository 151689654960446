// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_topbar__21Vtj {\n  padding: 0 2rem;\n  min-height: 64px;\n  height: 64px;\n  border-bottom: 1px solid #e4e9f0;\n  color: #595c97;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center; }\n  @media (max-width: 575px) {\n    .style_topbar__21Vtj {\n      padding: 0 1.06rem; } }\n\n[data-kit-theme='dark'] .style_topbar__21Vtj {\n  background: #0c0c1b;\n  color: #aeaee0;\n  border-bottom: 1px solid #232135; }\n", ""]);
// Exports
exports.locals = {
	"topbar": "style_topbar__21Vtj"
};
module.exports = exports;
