import axios from 'axios'
import store from 'store'

const apiClient = axios.create({
  baseURL: '/api/v1',
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers['x-api-key'] = accessToken
  }
  return request
})

apiClient.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error(error.toJSON())
    return Promise.reject(error.response)
  },
)

export default apiClient
