export default function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/dashboard/alpha',
      // roles: ['admin'], // set user roles with access to this route,
    },
    {
      title: 'Analytics',
      key: 'analytics',
      icon: 'fe fe-pie-chart',
      roles: ['admin'],
      children: [
        {
          title: 'Key Performance Indicators (KPIs)',
          key: 'keyPerformanceIndicators',
          url: '/dashboard/key-performance-indicators',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings',
      url: '/settings',
      roles: ['admin'],
      children: [
        {
          title: 'User Management',
          key: 'usersjs',
          url: '/settings/users',
        },
      ],
    },
  ]
}
