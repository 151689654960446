import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard/alpha')),
  },
  {
    path: '/charts/:id',
    Component: lazy(() => import('components/semantic/ChartViewer')),
  },
  {
    path: '/analytics/key-performance-indicators',
    Component: lazy(() =>
      import('components/semantic/KPIDashboard/KeyPerformanceIndicators'),
    ),
  },
  {
    path: '/facesheet/:id',
    Component: lazy(() => import('components/semantic/FaceSheet')),
  },
  // Settings Pages
  {
    path: '/settings/users',
    Component: lazy(() => import('pages/settings/users')),
  },
  {
    path: '/settings/account',
    Component: lazy(() => import('pages/settings/account')),
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    {routes.map(({ path, Component }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={true}
                        render={props => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component {...props} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
