import apiClient from 'services/axios'
import store from 'store'
import { notification } from 'antd'

export async function login(email, password) {
  // console.log(`${email}, ${password}`)
  store.remove('accessToken')
  store.remove('username')
  return apiClient
    .post('/users/login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
          store.set('username', email)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      if (err.message) {
        notification.error({ message: err.message })
      }
    })
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  const user = store.get('username')
  if (user)
    return apiClient
      .get(`/users/${user}`)
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  return false
}

export async function logout() {
  return apiClient
    .get('/users/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}
