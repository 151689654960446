// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.style_breadcrumbs__1GI9- {\n  padding: 1.33rem 2rem 0;\n  font-size: 1.13rem; }\n\n.style_current__2oTdN {\n  color: #141322; }\n\n.style_arrow__2FG0q {\n  display: inline-block;\n  margin: 0 0.66rem; }\n  .style_arrow__2FG0q:after {\n    display: inline-block;\n    content: '·';\n    color: #a1a1c2; }\n\n[data-kit-theme='dark'] .style_breadcrumbs__1GI9- {\n  color: #aeaee0; }\n\n[data-kit-theme='dark'] .style_current__2oTdN {\n  color: #fff; }\n\n[data-kit-theme='dark'] .style_arrow__2FG0q:after {\n  color: #aeaee0; }\n", ""]);
// Exports
exports.locals = {
	"breadcrumbs": "style_breadcrumbs__1GI9-",
	"current": "style_current__2oTdN",
	"arrow": "style_arrow__2FG0q"
};
module.exports = exports;
