import React, { useContext, useState, useEffect, useRef } from 'react'
import { Layout, Menu, notification } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import MenuContext from './MenuContext'
import {
  HomeOutlined,
  PieChartOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import store from 'store'
import logo from './full_color_wordmark.png'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import { Tooltip } from '../../Tooltip'
import { useSelector } from 'react-redux'
import { CounterBadge } from '../../CounterBadge'

const { Sider } = Layout
const { Item, SubMenu, Divider } = Menu

const MenuPanel = () => {
  const {
    notes,
    currNoteId,
    chartId,
    actions: { setCurrNoteId },
  } = useContext(MenuContext)

  const isAdmin = useSelector(state => state.user.role) === 'admin'

  const [selectedKey, setSelectedKey] = useState()
  const [openKeys, setOpenKeys] = useState(
    store.get('app.menu.openedKeys') || ['chartNotes'],
  )

  const pathname = useLocation().pathname

  useEffect(() => {
    if (pathname.startsWith('/charts')) {
      setSelectedKey(String(currNoteId))
    } else {
      setSelectedKey(pathname)
    }
  }, [pathname, currNoteId])

  useEffect(() => {
    if (notes) {
      const currCategory = notes.find(note => note.id === currNoteId)?.category
      setOpenKeys(openKeys => {
        if (currCategory && !openKeys.includes(currCategory)) {
          return [...openKeys, currCategory]
        }
        return openKeys
      })
    }
  }, [notes, currNoteId])

  function buildMultiNoteMenu() {
    const notesByCategories = {}
    notes.forEach(note => {
      if (notesByCategories[note.category]) {
        notesByCategories[note.category].push(note)
      } else {
        notesByCategories[note.category] = [note]
      }
    })

    const calcCategoryEvidence = (category, notes) => {
      return notes
        .filter(note => note.category === category)
        .reduce((acc, curr) => acc + curr.evidenceCount, 0)
    }

    return Object.entries(notesByCategories)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([category, notes]) => {
        const sumCategoryEvidence = calcCategoryEvidence(category, notes)
        return (
          <SubMenu
            key={category}
            title={category}
            icon={
              sumCategoryEvidence > 0 && (
                <CountContainer>
                  <Tooltip text={'Relevant evidence found in category'}>
                    <CounterBadge count={sumCategoryEvidence} size="small" />
                  </Tooltip>
                </CountContainer>
              )
            }
          >
            {notes.map(note => (
              <Item
                key={note.id}
                onClick={() => setCurrNoteId(note.id)}
                icon={
                  note.evidenceCount > 0 && (
                    <CountContainer>
                      <Tooltip text={'Relevant evidence found in note'}>
                        <CounterBadge count={note.evidenceCount} size="small" />
                      </Tooltip>
                    </CountContainer>
                  )
                }
              >
                {note.title}
              </Item>
            ))}
          </SubMenu>
        )
      })
  }

  function handleItemClick({ key }) {
    setSelectedKey(key)
  }

  function handleOpenChange(keys) {
    store.set('app.menu.openedKeys', keys)
    setOpenKeys(keys)
  }

  const winRef = useRef()
  function openFaceSheet() {
    if (!winRef.current || winRef.current.close) {
      winRef.current = window.open(
        `/#/facesheet/${chartId}`,
        'SemanticFaceSheet',
        // TODO: we should persist user preferred dimensions with event listeners and session storage
        'width=1280,height=720',
      )
      if (!winRef.current) {
        notification.warn({
          title: 'Popup blocked',
          description: 'Please enable popups to view the Face Sheet',
        })
      }
    } else {
      winRef.current.focus()
    }
  }

  useEffect(() => {
    // close child windows when leaving record
    if (!notes && winRef.current) {
      winRef.current.close()
    }
  }, [notes])

  return (
    <Sider>
      <div
        style={{
          height: '63px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" style={{ height: '40px' }} />
      </div>
      <div
        style={{
          position: 'absolute',
          inset: '63px 0 0 0',
          borderRight: '1px solid #e4e9f0',
        }}
      >
        <PerfectScrollbar>
          <Menu
            style={{
              borderRight: 'none',
              overflowX: 'hidden',
            }}
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleItemClick}
            onOpenChange={handleOpenChange}
            openKeys={openKeys}
          >
            <Item key="/dashboard" icon={<HomeOutlined />}>
              <Link to={'/dashboard'}>Dashboard</Link>
            </Item>
            {isAdmin && (
              <SubMenu
                key="/analytics"
                title="Analytics"
                icon={<PieChartOutlined />}
              >
                <Item key="/analytics/key-performance-indicators">
                  <Link to={'/analytics/key-performance-indicators'}>
                    Key Performance Indicators
                  </Link>
                </Item>
              </SubMenu>
            )}
            <SubMenu
              key="/settings"
              title="Settings"
              icon={<SettingOutlined />}
            >
              {isAdmin && (
                <Item key="/settings/users">
                  <Link to={'/settings/users'}>User Management</Link>
                </Item>
              )}
              <Item key="/settings/account">
                <Link to={'/settings/account'}>Account</Link>
              </Item>
            </SubMenu>
            {notes && (
              <>
                <Divider style={{ margin: '0 20px' }} />
                <Item onClick={openFaceSheet}>Face Sheet</Item>
                <SubMenu key="chartNotes" title="Chart Notes">
                  {buildMultiNoteMenu()}
                </SubMenu>
              </>
            )}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Sider>
  )
}

// TODO: make reusible badge component
const CountContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: translateX(calc(-100% - 4px));
`

export default MenuPanel
