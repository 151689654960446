import React, { createContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const UserContext = createContext()

// Starting to create UserContext to lay foundation for when
// migrating away from redux/sagas.
export const UserProvider = ({ children }) => {
  // TODO: after nuking redux, login should be done with Auth Context Provider
  // so shouldn't be seeding state from redux
  const defaultCategoryColors = {
    admission: '#C88AF5',
    dischargeSummary: '#243569',
    edTriageNote: '#A0D1C9',
    edProvNote: '#90A5E3',
    progress: '#A0CEFA',
    opNote: '#9615F5',
    consult: '#4C72E6',
    edProcedure: '#44CBF2',
    psProgress: '#4C749B',
    edNote: '#6107A3',
    briefOpNot: '#058871',
    initAssess: '#56FFE1',
    nrsgNote: '#95C0CC',
    caseSummary: '#47A4FC',
    uncategorized: '#1f4fe0',
  }
  const [categoryColors, setCategoryColors] = useState(defaultCategoryColors)
  const userSettings = useSelector(state => state.user.settings)
  useEffect(() => {
    if (userSettings?.categoryColors) {
      setCategoryColors(userSettings.categoryColors)
    }
  }, [userSettings])

  const providerValue = {
    settings: {
      categoryColors,
      setCategoryColors,
    },
  }

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
