import React from 'react'
import styled from 'styled-components'

const Badge = styled.div`
  height: ${props => props.height + 4}px;
  width: ${props => props.width + 4}px;
  border-radius: ${props => (props.height + 4) / 2}px;
  background-color: ${props => props.color};
  border: 1px solid white;
  color: white;
  font-size: ${props => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Root = styled.div`
  position: 'relative';
`

export const CounterBadge = ({
  count = 0,
  color = '#4b7cf3',
  size = 'medium',
  offset = [0, 0],
  showZero = false,
  children,
}) => {
  const sizes = {
    small: 12,
    medium: 14,
  }

  if (count === 0 && !showZero) return children || null

  if (children) {
    return (
      <Root>
        {children}
        <Badge
          height={sizes[size]}
          width={sizes[size] * String(count).length}
          color={color}
          style={{
            position: 'absolute',
            top: offset[1],
            right: -offset[0],
            transform: 'translate(50%, -50%)',
          }}
        >
          {count}
        </Badge>
      </Root>
    )
  }

  return (
    <Badge
      height={sizes[size]}
      width={sizes[size] * String(count).length}
      color={color}
    >
      {count}
    </Badge>
  )
}
