import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'

const animationDuration = 200 //ms

export const Tooltip = ({ text, children }) => {
  const root = document.getElementById('portal-root')

  const [xy, setXy] = useState([0, 0])
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div
        onMouseEnter={e => {
          const rect = e.target.getBoundingClientRect()
          setXy([rect.x + rect.width / 2, rect.bottom + window.scrollY])
          setVisible(true)
        }}
        onMouseLeave={() => setVisible(false)}
        style={{ position: 'relative' }}
      >
        {children}
      </div>
      {createPortal(
        <Transition in={visible} timeout={animationDuration} unmountOnExit>
          {state => (
            <Popper state={state} xy={xy}>
              {text}
            </Popper>
          )}
        </Transition>,
        root,
      )}
    </>
  )
}

const Popper = styled.div`
  background-color: rgba(70, 70, 70, 0.92);
  color: white;
  position: absolute;
  border-radius: 3px;
  left: ${({ xy }) => xy[0]}px;
  top: ${({ xy }) => xy[1]}px;
  transform: translateX(-50%);
  text-align: center;
  font-size: 11px;
  white-space: nowrap;
  padding: 3px;
  transition: opacity ${animationDuration}ms;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  pointer-events: none;
`
